#sign-in .card-panel {
  padding: 30px;
}

.dataTables_wrapper select {
  display: inline-block;
  width: auto;
}

.dataTables_wrapper th {
  text-align: center;
}

.date-picker-wrapper {
  z-index: 9999;
}

table.table th {
  text-align: center;
}

#loading {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  margin: 0;
}

.orgchart td.right {
  float: none !important;
}

.orgchart td.left {
  float: none !important;
}

.mtop10 {
  margin-top: 10px;
}

.mtop20 {
  margin-top: 20px;
}

.mtop30 {
  margin-top: 30px;
}

.mtop40 {
  margin-top: 40px;
}

.mtop50 {
  margin-top: 50px;
}

.mtop60 {
  margin-top: 60px;
}

.top-align {
  vertical-align: top !important;
}

.bottom-align {
  vertical-align: bottom !important;
}

.bold {
  font-weight: bold;
}

.clear:before,
.clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.click {
  cursor: pointer;
}

.browser-default {
  border-color: #d7d8d7;
}

fieldset {
  margin-top: 20px;
  margin-bottom: 20px;
}

fieldset > legend {
  font-weight: bold;
}

/*================ Modal ===========*/
.modal.modal-large {
  width: 75%;
}

.modal.modal-full {
  top: 0% !important;
  width: 100vw;
  max-height: 100vh;
}

/*================ Element disabled ===========*/
.is-disabled {
  opacity: .5;
  position: relative;
}

.is-disabled::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
}

/*================ VueJS Transitions ===========*/
.bounce-transition {
  display: inline-block;
  /* otherwise scale animation won't work */
}

.bounce-enter {
  animation: bounce-in .5s;
}

.bounce-leave {
  animation: bounce-out .5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}

/* always present */
.expand-transition {
  transition: all 1s ease;
  padding: 10px;
  overflow: hidden;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

/*================/ VueJS Transitions ===========*/
#toast-container {
  z-index: 100000 !important;
}

.table-ocha-report-status td:last-child {
  font-weight: bold;
}

.table-header-middle th {
  vertical-align: middle !important;
}

.label {
  padding: 2px 1px 1px 2px;
  border-radius: 2px;
  vertical-align: middle;
  display: inline-block;
}

.label.red {
  background: red;
  color: white;
}

.label.green {
  background: green;
  color: white;
}

.label.blue {
  background: dodgerblue;
  color: white;
}

.label.orange {
  background: darkorange;
  color: white;
}

.select2-container--default .select2-results__option[aria-selected=true]:before {
  font-family: Material-Design-Icons;
  content: "\e899 ";
  color: dodgerblue;
}

.select2-container--default .select2-results__option[aria-selected=true]:hover:before {
  color: white;
}

.dt-button {
  text-decoration: none;
  color: #FFF;
  background-color: #42A5F5;
  text-align: center;
  letter-spacing: .5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  height: 25.2px;
  line-height: 25px;
  font-size: .8rem;
}

.btn.btn-ghost.green {
  color: #4CAF50 !important;
  background-color: transparent !important;
  border: 1px solid #4CAF50 !important;
  text-shadow: none;
  box-shadow: none;
}

.btn.btn-fake {
  cursor: auto;
  box-shadow: none;
}

.btn.btn-fake:hover {
  box-shadow: none;
}

.collapsible {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
